import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import questions from "./questions";
import { defaultStorageHelper } from "@spry/campaign-client";
import Loading from "../../../Components/Loading";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { publicCampaignClient } from "../../../api";
import MyModal from "../../../Components/MyModal";

const Question = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [questionIndex, setQuestionIndex] = useState(0);
  let questionList = questions[i18n.language];
  const [wasCorrect, setWasCorrect] = useState(null as boolean | null);
  const [optionSelected, setOptionSelected] = useState(false);
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  let lang = defaultStorageHelper.get("lang");
  let schoolName = defaultStorageHelper.get("schoolName");
  const sessionKey = defaultStorageHelper.get("sessionKey");
  const [visited, setVisited] = useState(false);
  const [choice, setChoice] = useState();

  useEffect(() => {
    if (!sessionKey) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    getParticipant();
    questionList = questions[lang!];
  }, [lang, i18n.language]);

  const getParticipant = async () => {
    setLoading(true);
    try {
      const { Item }: any = await publicCampaignClient.call("getParticipant", {
        sessionKey: sessionKey,
        schoolName: schoolName,
      });
      if (Item.trivia.length > 0) {
        setQuestionIndex(Item.trivia.length);
      }
    } catch (error) {
      console.log(error);
      setSubmissionError(true);
    }
    setLoading(false);
  };

  const handleChooseAnswer = (choice?: any, i?: any, e?: any) => {
    let isCorrect = questionList[questionIndex].ans == choice.v2Option;
    if (isCorrect) {
      defaultStorageHelper.set(
        "result",
        `${
          parseInt(
            defaultStorageHelper.get("result")
              ? defaultStorageHelper.get("result")!
              : "0"
          ) + 1
        }`
      );
    }
    setChoice(choice.v2Option);
    setWasCorrect(isCorrect);
  };

  const handleGotoNext = async (e: any) => {
    setOptionSelected(false);
    setLoading(true);
    e.preventDefault();
    try {
      const response: any = await publicCampaignClient.call("addTrivia", {
        sessionKey: sessionKey,
        question: questionList[questionIndex].q,
        answer: choice,
        schoolName: schoolName,
        questionNumber: questionIndex + 1,
      });
      if (questionIndex + 1 === 5) {
        navigate("/results");
      } else {
        setWasCorrect(null);
        setQuestionIndex(questionIndex + 1);
      }
    } catch (error) {
      console.log(error);
      setSubmissionError(true);
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}
      {submissionError ? (
        <MyModal
          copy={
            i18n.language === "en"
              ? "Something went wrong, please try again later."
              : "Quelque chose c'est mal passé. Merci d'essayer plus tard."
          }
        />
      ) : (
        <></>
      )}
      {visited === false && (
        <>
          <section className="section-main section-main--alt section--full">
            <div className="shell">
              <div className="section__inner">
                <div className="section__content">
                  <div className="section__content-inner">
                    <h2>{t("trivia.8")}</h2>
                    {questionIndex > 0 ? (
                      <h5>
                        {t("trivia.5")}
                        {questionList[questionIndex].questionNumber}{" "}
                        {t("trivia.7")}
                      </h5>
                    ) : (
                      <h5>
                        {t("trivia.5")}
                        {questionList[questionIndex].questionNumber}{" "}
                        {t("trivia.6")}
                      </h5>
                    )}

                    <a
                      onClick={() => setVisited(true)}
                      className="btn btn--warning section__btn hidden-sm hidden-xs"
                    >
                      {t("trivia.10")} {questionList[questionIndex].name}
                    </a>
                  </div>
                  <div className="section__figure section__content-figure">
                    <img
                      src="assets/images/svg/figures-2.svg"
                      alt=""
                      width={423}
                      height={586}
                      className="hidden-xs hidden-sm"
                    />
                    <img
                      src="assets/images/svg/figures-2-mobile.svg"
                      alt=""
                      width={378}
                      height={223}
                      className="visible-xs-block visible-sm-block"
                    />
                  </div>
                </div>
                <div className="section__image section__image--alt">
                  <img
                    src={
                      i18n.language === "en"
                        ? `assets/images/svg/map${questionList[questionIndex].questionNumber}.svg`
                        : `assets/images/svg/map${questionList[questionIndex].questionNumber}-fr.svg`
                    }
                    alt=""
                    width={1135}
                    height={754}
                    className="hidden-xs hidden-sm"
                  />
                  <img
                    src={
                      i18n.language === "en"
                        ? `assets/images/svg/map${questionList[questionIndex].questionNumber}-mobile.svg`
                        : `assets/images/svg/map${questionList[questionIndex].questionNumber}-mobile-fr.svg`
                    }
                    alt=""
                    width={519}
                    height={398}
                    className="visible-xs-block visible-sm-block"
                  />
                  <a
                    onClick={() => setVisited(true)}
                    className="btn btn--warning section__btn visible-xs-block visible-sm-block"
                    style={{ fontSize: "14px" }}
                  >
                    {t("trivia.10")} {questionList[questionIndex].name}
                  </a>
                </div>
                <div className="section__background hidden-xs hidden-sm">
                  <img
                    src="assets/images/background-1.png"
                    alt=""
                    width={2088}
                    height={3083}
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {wasCorrect === null && visited && (
        <section className="section-main section-main--secondary section--full">
          <div className="shell">
            <div className="section__image">
              <img
                src={`assets/images/temp/quesiton${questionList[questionIndex].questionNumber}-img.jpg`}
                alt=""
                width={1240}
                height={850}
                className="hidden-xs hidden-sm"
              />
              <img
                src={`assets/images/temp/quesiton${questionList[questionIndex].questionNumber}-img-mobile.jpg`}
                alt=""
                width={1240}
                height={850}
                className="visible-xs-block visible-sm-block"
              />
              <div className="section__image-background visible-xs-block visible-sm-block">
                <img
                  src="assets/images/svg/background-2-mobile.svg"
                  alt=""
                  width={446}
                  height={3083}
                />
              </div>
            </div>
            <div className="section__content">
              <div className="section__content-inner">
                <div className="form form--questions section__form">
                  <div className="form__body">
                    <>
                      <div className="form__head">
                        <p>
                          {" "}
                          Question {questionList[questionIndex].questionNumber}/
                          {questionList.length}
                        </p>
                      </div>
                      <div className="section section--trivia">
                        <form onSubmit={handleSubmit(handleChooseAnswer)}>
                          <div className="section section--trivia">
                            <h2> Question {questionIndex + 1}</h2>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: questionList[questionIndex].s,
                              }}
                            ></p>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: questionList[questionIndex].q,
                              }}
                            ></p>{" "}
                            <p />
                            <div className="radios form__radios">
                              <ul className="list-answers">
                                {questionList[questionIndex].choices.map(
                                  (qst: any, idx: any) => {
                                    return (
                                      <li key={`${qst}${idx}`}>
                                        <div className="radio">
                                          <input
                                            type="radio"
                                            id={`field-radio-${idx + 1}`}
                                            key={`${qst}${idx}`}
                                            value={qst}
                                            onClick={() =>
                                              setOptionSelected(true)
                                            }
                                            {...register(`v2Option`)}
                                          />
                                          <label
                                            htmlFor={`field-radio-${idx + 1}`}
                                            style={{ padding: "2rem 4rem" }}
                                          >
                                            {qst}
                                          </label>
                                        </div>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </div>
                          </div>{" "}
                          <br />
                          <div className="form__foot">
                            <button
                              className="btn btn--warning"
                              type="submit"
                              disabled={!optionSelected}
                            >
                              {t("trivia.1")}
                            </button>
                          </div>
                        </form>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>

            <div className="section__background hidden-xs hidden-sm">
              <img
                src="assets/images/background-1.png"
                alt=""
                width={2088}
                height={3083}
              />
            </div>
          </div>
        </section>
      )}
      {wasCorrect === true && (
        <section className="section-main section-main--secondary section--full">
          <div className="shell">
            <div className="section__image">
              <img
                src={`assets/images/temp/quesiton${questionList[questionIndex].questionNumber}-img.jpg`}
                alt=""
                width={1240}
                height={850}
                className="hidden-xs hidden-sm"
              />
              <img
                src={`assets/images/temp/quesiton${questionList[questionIndex].questionNumber}-img-mobile.jpg`}
                alt=""
                width={1240}
                height={850}
                className="visible-xs-block visible-sm-block"
              />
              <div className="section__image-background visible-xs-block visible-sm-block">
                <img
                  src="assets/images/svg/background-2-mobile.svg"
                  alt=""
                  width={446}
                  height={3083}
                />
              </div>
            </div>
            <div className="section__content">
              <div className="section__content-inner">
                <div className="feature section__feature">
                  <div className="feature__inner">
                    <i className="ico-check">
                      <img
                        src="assets/images/svg/check.svg"
                        alt=""
                        width={100}
                        height={100}
                      />
                    </i>
                    <h2> {t("trivia.3")}</h2>
                  </div>
                  <h6>{questionList[questionIndex].ans}</h6>
                  <p style={{ textAlign: "left" }}>
                    {questionList[questionIndex].questionNumber === 2
                      ? `${questionList[questionIndex].tag} ${
                          schoolName === "Other" || schoolName === "zOther"
                            ? ""
                            : schoolName
                        } ${questionList[questionIndex].tag2}`
                      : questionList[questionIndex].tag}
                  </p>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setVisited(false);
                      handleGotoNext(e);
                    }}
                    className="btn btn--warning feature__btn"
                  >
                    {t("trivia.2")}
                  </a>
                </div>
              </div>
            </div>
            <div className="section__background hidden-xs hidden-sm">
              <img
                src="assets/images/background-1.png"
                alt=""
                width={2088}
                height={3083}
              />
            </div>
          </div>
        </section>
      )}
      {wasCorrect === false && (
        <section className="section-main section-main--secondary section--full">
          <div className="shell">
            <div className="section__image">
              <img
                src={`assets/images/temp/quesiton${questionList[questionIndex].questionNumber}-img.jpg`}
                alt=""
                width={1240}
                height={850}
                className="hidden-xs hidden-sm"
              />
              <img
                src={`assets/images/temp/quesiton${questionList[questionIndex].questionNumber}-img-mobile.jpg`}
                alt=""
                width={1240}
                height={850}
                className="visible-xs-block visible-sm-block"
              />
              <div className="section__image-background visible-xs-block visible-sm-block">
                <img
                  src="assets/images/svg/background-2-mobile.svg"
                  alt=""
                  width={446}
                  height={3083}
                />
              </div>
            </div>
            <div className="section__content">
              <div className="section__content-inner">
                <div className="feature section__feature">
                  <div className="feature__inner">
                    <i className="ico-check">
                      <img
                        src="assets/images/svg/wrong.svg"
                        alt=""
                        width={100}
                        height={100}
                      />
                    </i>
                    <h2> {t("trivia.4")}</h2>
                  </div>
                  <h6>{questionList[questionIndex].ans}</h6>
                  <p style={{ textAlign: "left" }}>
                    {questionList[questionIndex].questionNumber === 2
                      ? `${questionList[questionIndex].tag} ${
                          schoolName === "Other" || schoolName === "zOther"
                            ? ""
                            : schoolName
                        } ${questionList[questionIndex].tag2}`
                      : questionList[questionIndex].tag}{" "}
                  </p>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setVisited(false);
                      handleGotoNext(e);
                    }}
                    className="btn btn--warning feature__btn"
                  >
                    {t("trivia.2")}
                  </a>
                </div>
              </div>
            </div>
            <div className="section__background hidden-xs hidden-sm">
              <img
                src="assets/images/background-1.png"
                alt=""
                width={2088}
                height={3083}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Question;
