const questions: any = {
  en: [
    {
      q: `Did you know RBC has a branch at `,
      questionNumber: 1,
      start: true,
    },
    {
      q: `Have you ever visited the RBC On Campus branch at `,
      questionNumber: 2,
    },
  ],

  fr: [
    {
      q: `Saviez-vous que RBC a une succursale sur votre `,
      questionNumber: 1,
      start: true,
    },
    {
      q: `Êtes-vous déjà passé à la succursale Campus RBC à `,
      questionNumber: 2,
    },
  ],
};

export default questions;
