const questions: any = {
  en: [
    {
      name: "Library",
      questionNumber: 1,
      start: true,
      image: "",
      s: "Automation is changing the skills needed in the workplace. 1 in 4 jobs will be affected in the next 10 years.",
      q: "Which skills will best prepare you to pivot on your career quest?",
      ans: "d) All of the above.",
      tag: "Human skills are the hot skills!  They’re more transferable than technical or job-specific skills, and, more valuable on your quest for the next great job.",
      choices: [
        "a) Creativity",
        "b) Critical thinking",
        "c) Problem solving",
        "d) All of the above.",
      ],
    },

    {
      name: "Athletic Centre",
      questionNumber: 2,
      start: false,
      image: "",
      s: "One of the biggest challenges entering the job market for the first time is lack of a professional network.",
      q: "What percentage of jobs do you think are filled through personal and professional connections?",
      ans: "d) 80%",
      tag: "Up to 80% of jobs are filled through personal and professional connections. 70% aren’t even published on public jobs sites! Your ",
      tag2: " RBC On Campus team offers workshops on how to build your personal brand and grow your network.",
      choices: ["a) 50%", "b) 60%", "c) 70%", "d) 80%"],
    },

    {
      name: "Food Court",
      questionNumber: 3,
      start: false,
      image: "",
      s: "A resume details your skills, training, work experience, education and accomplishments.",
      q: "If you don’t have past job experience to highlight, you should focus on your:",
      ans: "c) Volunteer experiences",
      tag: "A resume should concisely communicate the benefits you’ll bring to a job.  If you’re just starting your career quest, begin with the basics that provide an overview of your skills, education and experience. If you’ve volunteered, include transferable skills, like communications or leadership, that you learned or used. They’re skills that many companies are looking for!",
      choices: [
        "a) Need for a job",
        "b) Relationships with employees who already work there",
        "c) Volunteer experiences",
        "d) Availability",
      ],
    },

    {
      name: "Student Residence",
      questionNumber: 4,
      start: false,
      image: "",
      s: "An interview is an opportunity to capture the person’s attention and spark their curiosity.",
      q: "What can you do to prepare for an interview?",
      ans: "b) Create a 30 second elevator pitch including who you are, what’s unique about you and what you have to offer.",
      tag: "During an interview, hone in on the things that differentiate you from the competition.  Be natural, honest and authentic.",
      choices: [
        "a) Bring a small gift",
        "b) Create a 30 second elevator pitch including who you are, what’s unique about you and what you have to offer.",
        "c) Dress fancy, it’s all about “the Vibe”",
        "d) Wing it, you’re a natural!",
      ],
    },

    {
      name: "Student Union",
      questionNumber: 5,
      start: false,
      image: "",
      s: "Everyone has a personal brand. It's how you're seen by others so you want it to be consistent, positive and professional. ",
      q: "What makes up your personal brand?",
      ans: "d) All of the above.",
      tag: "Your personal brand follows you everywhere. 90% of employers hire directly from social media and 79% of HR professionals have denied a job to a candidate due to inappropriate content.   A strong, positive online presence can position you for career growth. ",
      choices: [
        "a) Who you are. Your purpose, core values and beliefs",
        "b) What you say. How you communicate and what you share about the value you bring to your work, your friends, your family.",
        "c) What you do. Your “visible” qualities that others see and experience, or the perceptions and expectations others form of you.",
        "d) All of the above.",
      ],
    },
  ],

  fr: [
    {
      name: "Bibliothèque",
      questionNumber: 1,
      start: true,
      image: "",
      s: "L’automatisation change les aptitudes attendues en milieu de travail. Au cours des dix prochaines années, un emploi sur quatre sera touché.",
      q: "Quelles aptitudes vous prépareront le mieux à atteindre votre objectif ?",
      ans: "d) Toutes ces réponses",
      tag: "Les aptitudes humaines sont très recherchées ! Elles sont plus facilement transférables que les aptitudes techniques ou propres à un emploi, et plus utiles dans votre recherche de l’emploi de rêve.",
      choices: [
        "a) La créativité",
        "b) La pensée critique",
        "c) La résolution de problèmes",
        "d) Toutes ces réponses",
      ],
    },

    {
      name: "Centre sportif",
      questionNumber: 2,
      start: false,
      image: "",
      s: "De nombreux obstacles peuvent se présenter lorsqu’on cherche à entrer sur le marché du travail. L’un des plus importants est la faiblesse de son réseau professionnel.",
      q: "Selon vous, quel pourcentage des emplois sont comblés grâce à des relations personnelles et professionnelles ?",
      ans: "d) 80 %",
      tag: "Jusqu’à 80 % des emplois sont comblés grâce à des relations personnelles et professionnelles ; 70 % des offres d’emploi ne sont même pas publiées sur des sites publics d’offres d’emploi ! Votre équipe Campus ",
      tag2: " RBC offre des ateliers sur les façons de bâtir votre marque personnelle et de développer votre réseau.",
      choices: ["a) 50 %", "b) 60 %", "c) 70 %", "d) 80 %"],
    },

    {
      name: "Aire de restauration",
      questionNumber: 3,
      start: false,
      image: "",
      s: "Un CV décrit vos aptitudes, votre formation, votre expérience professionnelle et vos réalisations",
      q: "Si vous n’avez pas d’expérience professionnelle à inscrire sur votre CV, vous devriez y souligner :",
      ans: "c) Vos expériences de bénévolat ",
      tag: "Un CV devrait indiquer de façon concise ce qui fait de vous le candidat idéal pour l’emploi convoité. Si vous êtes en début de carrière, commencez par donner un aperçu de vos aptitudes, de votre formation et de votre expérience. Si vous avez fait du bénévolat, indiquez des aptitudes transférables, comme des aptitudes pour la communication ou le leadership, que vous avez acquises ou mises en pratique. Ce sont des aptitudes recherchées par de nombreuses entreprises !",
      choices: [
        "a) Votre besoin d’un emploi",
        "b) Vos liens avec des employés qui travaillent déjà chez l’employeur qui vous intéresse ",
        "c) Vos expériences de bénévolat ",
        "d) Votre disponibilité",
      ],
    },

    {
      name: "Résidence pour étudiants",
      questionNumber: 4,
      start: false,
      image: "",
      s: "Une entrevue est l’occasion de capter l’attention de la personne et de susciter sa curiosité.",
      q: "Que pouvez-vous faire pour vous préparer à une entrevue ?",
      ans: "b) Préparer une présentation éclair de 30 secondes, qui décrit ce qui vous distingue et ce que vous avez à offrir.",
      tag: "Pendant l’entrevue, démontrez ce qui vous différencie et ce qui vous démarque de la concurrence. Soyez naturel, franc et authentique.",
      choices: [
        "a) Apporter un petit cadeau",
        "b) Préparer une présentation éclair de 30 secondes, qui décrit ce qui vous distingue et ce que vous avez à offrir.",
        "c) Porter une tenue chic, pour « faire bonne impression »",
        "d) Rien ; improviser et agir avec spontanéité !",
      ],
    },

    {
      name: "Association étudiante",
      questionNumber: 5,
      start: false,
      image: "",
      s: "Chacun a sa marque personnelle. Vous devez chercher à créer une marque cohérente, positive et professionnelle qui reflète la façon dont vous voulez que les autres vous perçoivent. ",
      q: "Qu’exprime votre marque personnelle ?",
      ans: "d) Toutes ces réponses.",
      tag: "Votre marque personnelle vous suit partout. 90 % des employeurs embauchent du personnel directement à partir des médias sociaux, et 79 % des professionnels en ressources humaines ont écarté un candidat à un emploi en raison de contenu inapproprié publié sur ses comptes de médias sociaux.  Une présence en ligne forte et positive peut favoriser votre avancement professionnel.",
      choices: [
        "a) Ce qui vous définit. Vos aspirations, vos valeurs fondamentales et vos convictions.",
        "b) Ce que vous dites ; ce que vous communiquez au sujet de votre apport à votre travail, à vos amis, à votre famille, etc. ",
        "c) Ce que vous faites ; vos qualités « visibles », ou les perceptions et les attentes que les autres ont à votre égard.",
        "d) Toutes ces réponses.",
      ],
    },
  ],
};

export default questions;
