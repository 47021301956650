import { defaultStorageHelper } from "@spry/campaign-client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import AdminFooter from "../../../Components/AdminFooter";
import AdminHeader from "../../../Components/AdminHeader";
import AdminLoading from "../../../Components/AdminLoading";

const Awarded = () => {
  const navigate = useNavigate();
  const email = defaultStorageHelper.get("email");
  const [submissionError, setSubmissionError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!email) {
      navigate("/admin");
    }
  }, []);

  return (
    <>
      <AdminHeader />
      <div className="main1">
        {submissionError ? (
          <div className="main1" style={{ minHeight: "100%" }}>
            <section className="section1 section-full1 section-main1 section--center1">
              <div className="shell1">
                <div className="section__inner1 section__inner--full1">
                  <div className="section__content1">
                    <Modal
                      isOpen
                      contentLabel="My dialog"
                      ariaHideApp={false}
                      className="popup"
                      portalClassName="modal"
                      onAfterOpen={() =>
                        (document.body.style.overflow = "hidden")
                      }
                      onAfterClose={() =>
                        (document.body.style.overflow = "unset")
                      }
                      style={{
                        overlay: {
                          zIndex: 1043,
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "rgba(0,0,0,0.5)",
                        },
                      }}
                    >
                      <div
                        className="mfp-wrap1 mfp-close-btn-in1 mfp-auto-cursor1 mfp-fade1 mfp-ready1"
                        tabIndex={-1}
                        style={{ overflow: "hidden auto" }}
                      >
                        <div className="mfp-container1 mfp-ajax-holder1 mfp-s-ready1">
                          <div className="mfp-content">
                            <div className="popup popup--alt popup--small">
                              <div className="popup__inner">
                                <div className="popup__body">
                                  <h4>
                                    Something went wrong, please try again
                                    later.
                                  </h4>
                                </div>
                                <div className="popup__foot">
                                  <a
                                    className="btn1 btn--default1 btn--primary1 js-popup-close1 popup__btn1"
                                    onClick={() => navigate("/admin")}
                                  >
                                    OK
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <></>
        )}

        {submitting ? (
          <div className="main1" style={{ minHeight: "100%" }}>
            <section className="section1 section-full1 section-main1 section--center1">
              <div className="shell1">
                <div className="section__inner1 section__inner--full1">
                  <div className="section__content1">
                    <AdminLoading />
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div className="section-form1 section-form--alt1">
            <div className="shell1">
              <div className="section__inner1">
                <div className="card-success1">
                  <div className="card__image1">
                    <img src="assets/images/success.svg" alt="" />
                  </div>
                  <h3>Success!</h3>
                  <p>
                    The reward has now been awarded to{" "}
                    <a href={`mailto:${email}`}>{email}</a>. They will receive
                    an email with their gift card shortly.
                  </p>
                  <a
                    className="btn1 card__btn1"
                    onClick={() => navigate("/admin")}
                  >
                    Done
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <AdminFooter />
    </>
  );
};

export default Awarded;
