import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { publicCampaignClient } from "../../../api";
import Loading from "../../../Components/Loading";
import { defaultStorageHelper } from "@spry/campaign-client";
import schools from "./schools";
import MyModal from "../../../Components/MyModal";
import LangModal from "../../../Components/LangModal";

type RegisterFormData = {
  langugage: string;
  schoolName: string;
};

const Landing = (inventoryCount: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormData>({ mode: "onChange" });

  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const language = defaultStorageHelper.get("lang");
  const { t, i18n } = useTranslation();
  const [other, setOther] = useState(false);

  useEffect(() => {
    defaultStorageHelper.remove("sessionKey");
    // defaultStorageHelper.remove("learnMoreLink");
    defaultStorageHelper.remove("schoolName");
    defaultStorageHelper.remove("schoolLocation");
    defaultStorageHelper.remove("lang");
    defaultStorageHelper.remove("schoolLocationLink");
    defaultStorageHelper.remove("result");
  }, []);

  const schoolsArray: any = schools.en.sort((a: any, b: any) =>
    a.schoolName > b.schoolName ? 1 : -1
  );

  const schoolsDropDown = schoolsArray.map((school: any) => {
    return (
      <option key={school.schoolName} value={school.schoolName}>
        {school.schoolName === "zOther" && i18n.language == "fr"
          ? school.frSchoolName.substring(1)
          : school.schoolName === "zOther" && i18n.language == "en"
          ? school.schoolName.substring(1)
          : school.schoolName}
      </option>
    );
  });

  const handleRegister = async (data: RegisterFormData) => {
    if (submitting && (language !== "fr" || "en")) {
      return;
    }

    const selectedSchool = schools.en.filter(
      (school: any) => school.schoolName === data.schoolName
    );

    setSubmitting(true);
    setSubmissionError(false);

    try {
      const { sessionKey }: any = await publicCampaignClient.call("register", {
        schoolName: data.schoolName,
        language: language,
        prizeFlow:
          inventoryCount && inventoryCount.inventoryCount <= 0 ? false : true,
      });
      if (sessionKey) {
        defaultStorageHelper.set("sessionKey", sessionKey);
        // defaultStorageHelper.set("learnMoreLink", selectedSchool[0].learnMore);
        defaultStorageHelper.set("schoolName", data.schoolName);
        defaultStorageHelper.set("schoolLocation", selectedSchool[0].location);
        defaultStorageHelper.set(
          "schoolLocationLink",
          i18n.language === "en"
            ? selectedSchool[0].locationLink
            : selectedSchool[0].frLocationLink
        );
        navigate(`/trivia-question`);
      } else {
        setSubmissionError(true);
      }
    } catch {
      setSubmissionError(true);
    }
    setSubmitting(false);
  };

  return (
    <section className="section-main section--full">
      {!inventoryCount && <Loading />}

      <LangModal />

      {submissionError ? (
        <MyModal
          copy={
            i18n.language === "en"
              ? "Something went wrong, please try again later."
              : "Quelque chose c'est mal passé. Merci d'essayer plus tard."
          }
        />
      ) : (
        <></>
      )}

      {submitting ? (
        <Loading />
      ) : (
        <div className="shell">
          <div className="section__image">
            <img
              src="assets/images/temp/image-1.jpg"
              alt=""
              width={1240}
              height={850}
              className="hidden-xs hidden-sm"
            />
            <img
              src="assets/images/temp/image-1-mobile.jpg"
              alt=""
              width={1240}
              height={850}
              className="visible-xs-block visible-sm-block"
            />
            <div className="section__image-background visible-xs-block visible-sm-block">
              <img
                src="assets/images/background-1-mobile.png"
                alt=""
                width={1215}
                height={3083}
              />
            </div>
          </div>
          <div className="section__content">
            <div className="section__content-inner">
              <div className="form form--primary section__form">
                <div className="form__head">
                  <h3>{t("landing.1")}</h3>
                  <h1>{t("landing.1a")}</h1>
                  <h4>{t("landing.1b")}</h4>
                  <h5>{t("landing.2")}</h5>
                  {inventoryCount && inventoryCount.inventoryCount > 0 ? (
                    <></>
                  ) : (
                    <h6>{t("landing.3")}</h6>
                  )}
                </div>
                <div className="form__body">
                  <div className="form__row">
                    <label
                      htmlFor="field-select-school"
                      className="form__label"
                    >
                      {t("landing.4")}
                    </label>
                    <div className="form__controls">
                      <div className="select">
                        <form onSubmit={handleSubmit(handleRegister)}>
                          <div style={{ marginBottom: "2rem" }}>
                            <select
                              id="field-select-school"
                              {...register("schoolName", {
                                onChange: (e) => {
                                  if (e.currentTarget.value == "zOther") {
                                    setOther(true);
                                  } else {
                                    setOther(false);
                                  }
                                },
                                required: {
                                  value: true,
                                  message: `${
                                    i18n.language === "en"
                                      ? "Please select your school."
                                      : "Veuillez sélectionner votre école."
                                  }`,
                                },
                              })}
                            >
                              <option value="">{t("landing.5")}</option>
                              {schoolsDropDown}
                            </select>
                          </div>
                          {errors.schoolName && (
                            <p className="error-message">
                              <i className="fas fa-exclamation-circle" />{" "}
                              {errors.schoolName.message}
                            </p>
                          )}
                          <div className="form__foot">
                            <button
                              type="submit"
                              className="btn btn--warning"
                              style={{ fontSize: "14px" }}
                            >
                              {t("landing.6")}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__figure section__content-figure visible-xs-block visible-sm-block">
              <img
                src="assets/images/svg/figures-mobile.svg"
                alt=""
                width={353}
                height={193}
              />
            </div>
          </div>
          <div className="section__figure hidden-xs hidden-sm">
            <img src="assets/images/svg/figures.svg" alt="" width={336} />
          </div>
          <div className="section__background hidden-xs hidden-sm">
            <img
              src="assets/images/background-1.png"
              alt=""
              width={2088}
              height={3083}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default Landing;
