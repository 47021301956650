import React, { useState, useEffect } from "react";
import AdminFooter from "../../../Components/AdminFooter";
import AdminHeader from "../../../Components/AdminHeader";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { campaignClient } from "../../../api";
import { emailRegex } from "../../../helpers/regexes";
import { defaultStorageHelper } from "@spry/campaign-client";
import AdminLoading from "../../../Components/AdminLoading";

type RegisterFormData = {
  email: string;
  preferredLanguage: string;
};

const EnterEmail = () => {
  useEffect(() => {
    defaultStorageHelper.remove("sessionKey");
    defaultStorageHelper.remove("email");
    defaultStorageHelper.remove("won");
    defaultStorageHelper.remove("awardedTime");
    defaultStorageHelper.remove("preferredLanguage");
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormData>({ mode: "onChange" });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  const handleRegister = async (data: RegisterFormData) => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    setSubmissionError(false);
    try {
      const { sessionKey, email, won, awardedTime, preferredLanguage }: any =
        await campaignClient.call("checkPrizeWinner", {
          email: data.email,
          preferredLanguage: data.preferredLanguage,
        });

      if (sessionKey) {
        defaultStorageHelper.set("sessionKey", sessionKey);
        defaultStorageHelper.set("email", email);
        defaultStorageHelper.set("won", won);
        preferredLanguage
          ? defaultStorageHelper.set("preferredLanguage", preferredLanguage)
          : defaultStorageHelper.set(
              "preferredLanguage",
              data.preferredLanguage
            );
        awardedTime !== undefined
          ? defaultStorageHelper.set(
              "awardedTime",
              `${new Date(awardedTime).toLocaleDateString()} - ${new Date(
                awardedTime
              ).toLocaleTimeString()}`
            )
          : defaultStorageHelper.set("awardedTime", "");
        navigate(`/admin/participant-status`);
      } else {
        setSubmissionError(true);
      }
    } catch {
      setSubmissionError(true);
    }
    setSubmitting(false);
  };

  return (
    <>
      <AdminHeader />
      <div className="main1">
        {submissionError ? (
          <div className="main1" style={{ minHeight: "100%" }}>
            <section className="section1 section-full1 section-main1 section--center1">
              <div className="shell1">
                {/* <div className="section__inner1 section__inner--full1"> */}
                <div className="section__content1">
                  <Modal
                    isOpen
                    contentLabel="My dialog"
                    ariaHideApp={false}
                    className="popup"
                    portalClassName="modal"
                    onAfterOpen={() =>
                      (document.body.style.overflow = "hidden")
                    }
                    onAfterClose={() =>
                      (document.body.style.overflow = "unset")
                    }
                    style={{
                      overlay: {
                        zIndex: 1043,
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0,0,0,0.5)",
                      },
                    }}
                  >
                    <div
                      className="mfp-wrap1 mfp-close-btn-in1 mfp-auto-cursor1 mfp-fade mfp-ready1"
                      tabIndex={-1}
                      style={{ overflow: "hidden auto" }}
                    >
                      <div className="mfp-container1 mfp-ajax-holder1 mfp-s-ready1">
                        <div className="mfp-content1">
                          <div className="popup popup--alt popup--small">
                            <div className="popup__inner">
                              <div className="popup__body">
                                <h4>
                                  Something went wrong, please try again later.
                                </h4>
                              </div>
                              <div className="popup__foot">
                                <a
                                  className="btn1 btn--default1 btn--primary1 js-popup-close1 popup__btn1"
                                  onClick={() => navigate("/admin")}
                                >
                                  OK
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <></>
        )}

        {submitting ? (
          <AdminLoading />
        ) : (
          <div className="section-form1 section-form--alt1">
            <div className="shell1">
              <div className="section__inner1">
                <div className="subscribe1">
                  <form onSubmit={handleSubmit(handleRegister)}>
                    <header className="subscribe__head1">
                      <h3>Enter an email</h3>
                      <p>
                        Confirm that the user participated by reviewing their
                        screenshot of the final screen of the experience. Once
                        confirmed, enter the email that a participant presents
                        and click submit.
                      </p>
                    </header>
                    <div className="subscribe__body1">
                      <label htmlFor="mail" className="subscribe__label1">
                        Email
                      </label>
                      <input
                        type="text1"
                        className="subscribe__field1 field1"
                        style={{ marginBottom: "2rem" }}
                        id="field-email"
                        defaultValue=""
                        placeholder="Email"
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Please enter an email.",
                          },
                          pattern: {
                            value: emailRegex,
                            message: "Please enter a valid email.",
                          },
                        })}
                      />
                      {errors.email && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.email.message}
                        </p>
                      )}

                      <label htmlFor="mail" className="subscribe__label1">
                        Participant Preferred Language
                      </label>
                      <select
                        style={{ marginBottom: "2rem" }}
                        className="subscribe__field1 field1"
                        id="field-customer"
                        {...register("preferredLanguage", {
                          required: {
                            value: true,
                            message: `${"Please participant preferred language."}`,
                          },
                        })}
                      >
                        <option value="">Select Language</option>
                        <option value="en">English</option>
                        <option value="fr">French</option>
                      </select>
                      {errors.preferredLanguage && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.preferredLanguage.message}
                        </p>
                      )}
                    </div>
                    <input
                      type="submit"
                      defaultValue="Submit"
                      className="subscribe__btn1 btn1"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <AdminFooter />
    </>
  );
};

export default EnterEmail;
