import { defaultStorageHelper } from "@spry/campaign-client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { publicCampaignClient } from "../../../api";

const SurveyComplete = (inventoryCount: any) => {
  const navigate = useNavigate();
  const sessionKey = defaultStorageHelper.get("sessionKey");
  const { t, i18n } = useTranslation();
  // const [loading, setLoading] = useState(false);
  // const result = defaultStorageHelper.get("result");
  // const [submissionError, setSubmissionError] = useState(false);
  // const [clickSurveyCompleteLink, setClickSurveyCompleteLink] = useState(false);
  // const [clickLearnMore, setClickLearnMore] = useState(false);
  // const learnMoreLink = defaultStorageHelper.get("learnMoreLink");
  const schoolName = defaultStorageHelper.get("schoolName");
  const schoolLocation = defaultStorageHelper.get("schoolLocation");
  const schoolLocationLink = defaultStorageHelper.get("schoolLocationLink");

  useEffect(() => {
    if (!sessionKey) {
      navigate("/");
    }
  }, []);

  // useEffect(() => {
  //   if (clickLearnMore == true) {
  //     postLearnMore();
  //   }
  // }, [clickLearnMore]);

  // const postLearnMore = async () => {
  //   setLoading(true);
  //   try {
  //     const res: any = publicCampaignClient.call("result", {
  //       sessionKey: sessionKey,
  //       result: result,
  //       schoolName: schoolName,
  //       learnMoreLink: learnMoreLink,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     setSubmissionError(true);
  //   }
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   if (clickSurveyCompleteLink == true) {
  //     postSurveyCompleteLink();
  //   }
  // }, [clickSurveyCompleteLink]);

  // const postSurveyCompleteLink = async () => {
  //   setLoading(true);
  //   try {
  //     const res: any = publicCampaignClient.call("result", {
  //       sessionKey: sessionKey,
  //       result: result,
  //       schoolName: schoolName,
  //       surveyCompleteLink:
  //         "https://www.rbcroyalbank.com/student-solution/index.html",
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     setSubmissionError(true);
  //   }
  //   setLoading(false);
  // };

  return (
    <section className="section-main section-main--alt section-alt section--full">
      <div className="shell">
        <div className="section__inner">
          <div className="section__content">
            <div className="section__content-inner">
              <h3>{t("complete-prize.1")}</h3>
              <p>
                {inventoryCount && inventoryCount.inventoryCount > 0
                  ? t("complete-prize.2")
                  : ""}
              </p>
              <div
                className="section__content-image"
                style={{ position: "static" }}
              >
                <div className="section__figure section__content-figure">
                  <img
                    src="assets/images/svg/figures-3.svg"
                    alt=""
                    width={183}
                    height={211}
                    className="hidden-xs hidden-sm"
                  />
                  <img
                    src="assets/images/svg/figures-3-mobile.svg"
                    alt=""
                    width={441}
                    height={192}
                    className="visible-xs-block visible-sm-block"
                  />
                </div>
              </div>
              <p>
                {t("complete-prize.3")}{" "}
                {schoolLocationLink !== "" ? (
                  <a href={`${schoolLocationLink}`} target="__blank">
                    {`${schoolName}`}, {schoolLocation}
                  </a>
                ) : (
                  schoolLocation
                )}
                .
              </p>
            </div>
          </div>
          <div className="section__image section__image--alt">
            <img
              src={
                inventoryCount && inventoryCount.inventoryCount > 0
                  ? i18n.language === "en"
                    ? "assets/images/svg/map6-prize.svg"
                    : "assets/images/svg/map6-prize-fr.svg"
                  : i18n.language === "en"
                  ? "assets/images/svg/map6.svg"
                  : "assets/images/svg/map6-fr.svg"
              }
              alt=""
              width={1085}
              height={648}
              className="hidden-xs hidden-sm"
            />
            <img
              src={
                inventoryCount && inventoryCount.inventoryCount > 0
                  ? i18n.language === "en"
                    ? "assets/images/svg/map6-prize-mobile.svg"
                    : "assets/images/svg/map6-prize-mobile-fr.svg"
                  : i18n.language === "en"
                  ? "assets/images/svg/map6-mobile.svg"
                  : "assets/images/svg/map6-prize-mobile-fr.svg"
              }
              alt=""
              width={519}
              height={398}
              className="visible-xs-block visible-sm-block"
            />
          </div>
          <div className="section__background hidden-xs hidden-sm">
            <img
              src="assets/images/background-1.png"
              alt=""
              width={2088}
              height={3083}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SurveyComplete;
