import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

export default function Admin() {
  const { t } = useTranslation();

  return (
    // <CampaignAuthRequired>
    <div className="wrapper1">
      <Outlet />
    </div>
    // </CampaignAuthRequired>
  );
}
