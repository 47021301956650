const AdminLoginHeader = () => {
  return (
    <header className="header1 header--small1">
      <div className="shell1">
        <div className="header__inner1">
          <a className="logo-small1">
            <img src="assets/images/logo-small.png" alt="" width={65} />
          </a>
        </div>
      </div>
    </header>
  );
};

export default AdminLoginHeader;
