import { defaultStorageHelper } from "@spry/campaign-client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { publicCampaignClient } from "../../../api";
import questions from "./questions";
import Modal from "react-modal";
import Loading from "../../../Components/Loading";
import { useTranslation } from "react-i18next";
import MyModal from "../../../Components/MyModal";

const SurveyQuestion = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  let questionList = questions[i18n.language];
  const [question, setQuestion] = useState<any>();
  const sessionKey = defaultStorageHelper.get("sessionKey");
  const schoolName = defaultStorageHelper.get("schoolName");
  const [loading, setLoading] = useState(false);
  const [initialIndex, setInitialIndex] = useState<number>(0);
  const [submissionError, setSubmissionError] = useState(false);
  let lang = defaultStorageHelper.get("lang");

  useEffect(() => {
    getParticipant();
    getQuestionDetails();
    questionList = questions[lang!];
  }, [lang, i18n.language]);

  useEffect(() => {
    if (!sessionKey) {
      navigate("/");
    }
    getParticipant();
    getQuestionDetails();
  }, [initialIndex]);

  const getParticipant = async () => {
    setLoading(true);
    try {
      const { Item }: any = await publicCampaignClient.call("getParticipant", {
        sessionKey: sessionKey,
        schoolName: schoolName,
      });
      if (Item.surveys.length > 0) {
        setInitialIndex(Item.surveys.length - 1);
      }
    } catch (error) {
      console.log(error);
      setSubmissionError(true);
    }
    setLoading(false);
  };

  const getQuestionDetails = () => {
    if (initialIndex < 2) {
      setQuestion(questionList[initialIndex].q);
    }
  };

  const handleGotoNext = async (e: any, answer: string) => {
    setLoading(true);
    if (initialIndex < 2) {
      setInitialIndex(initialIndex + 1);
    }
    if (initialIndex + 1 == 2) {
      navigate("/survey-complete");
    }
    getQuestionDetails();
    try {
      const response: any = await publicCampaignClient.call("addSurveys", {
        sessionKey: sessionKey,
        question: question,
        answer: answer,
        schoolName: schoolName,
        surveyNumber: questionList[initialIndex].questionNumber,
      });
    } catch (error) {
      console.log(error);
      setSubmissionError(true);
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}
      {submissionError ? (
        <MyModal
          copy={
            i18n.language === "en"
              ? "Something went wrong, please try again later."
              : "Quelque chose c'est mal passé. Merci d'essayer plus tard."
          }
        />
      ) : (
        <></>
      )}

      <section className="section-main section-main--secondary section--full">
        <div className="shell">
          <div className="section__image">
            <img
              src="assets/images/temp/survey-img.jpg"
              alt=""
              width={1240}
              height={850}
              className="hidden-xs hidden-sm"
            />
            <img
              src="assets/images/temp/survey-img-mobile.jpg"
              alt=""
              width={1240}
              height={850}
              className="visible-xs-block visible-sm-block"
            />
            <div className="section__image-background visible-xs-block visible-sm-block">
              <img
                src="assets/images/svg/background-2-mobile.svg"
                alt=""
                width={446}
                height={3083}
              />
            </div>
          </div>

          <div className="section__content">
            <div className="section__content-inner">
              <div className="form form--questions section__form">
                <div className="form__body">
                  <>
                    <div className="form__head">
                      <p>
                        Question {questionList[initialIndex].questionNumber}/
                        {questionList.length}
                      </p>
                    </div>
                    <div className="section section--trivia">
                      <div className="box">
                        <h4>
                          {question} {schoolName}?
                        </h4>
                      </div>
                      <div className="feature section__feature">
                        <button
                          disabled={loading}
                          onClick={(e) => {
                            handleGotoNext(e, "Yes");
                          }}
                          className="btn feature__btn"
                          style={{ backgroundColor: "#ffc82d", color: "black" }}
                        >
                          {i18n.language === "en" ? "Yes" : "Oui"}
                        </button>
                      </div>
                      <div className="feature section__feature">
                        <button
                          disabled={loading}
                          onClick={(e) => {
                            handleGotoNext(e, "No");
                          }}
                          className="btn feature__btn"
                          style={{ backgroundColor: "#ffc82d", color: "black" }}
                        >
                          {i18n.language === "en" ? "No" : "Non"}
                        </button>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="section__background hidden-xs hidden-sm">
            <img
              src="assets/images/background-1.png"
              alt=""
              width={2088}
              height={3083}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default SurveyQuestion;
