import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

const MyModal = ({ copy }: { copy: string }) => {
  const navigate = useNavigate();
  return (
    <div className="main" style={{ minHeight: "100%" }}>
      <section className="section section-full section-main section--center">
        <div className="shell">
          <div className="section__inner section__inner--full">
            <div className="section__content">
              <Modal
                isOpen
                contentLabel="My dialog"
                ariaHideApp={false}
                className="popup"
                portalClassName="modal"
                onAfterOpen={() => (document.body.style.overflow = "hidden")}
                onAfterClose={() => (document.body.style.overflow = "unset")}
                style={{
                  overlay: {
                    zIndex: 1043,
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0,0,0,0.5)",
                  },
                }}
              >
                <div
                  className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready"
                  tabIndex={-1}
                  style={{ overflow: "hidden auto" }}
                >
                  <div className="mfp-container mfp-ajax-holder mfp-s-ready">
                    <div className="mfp-content">
                      <div className="popup popup--alt popup--small">
                        <div className="popup__inner">
                          <div className="popup__body">
                            <h4>{copy}.</h4>
                          </div>
                          <div className="popup__foot">
                            <a
                              className="btn btn--warning"
                              onClick={() => navigate("/")}
                            >
                              OK
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MyModal;
