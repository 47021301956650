import {
  defaultStorageHelper,
  SPRYCampaignClient,
} from "@spry/campaign-client";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { campaignClient } from "../../../api";
import AdminFooter from "../../../Components/AdminFooter";
import AdminHeader from "../../../Components/AdminHeader";
import { Auth } from "aws-amplify";
import AdminLoading from "../../../Components/AdminLoading";

const ParticipantStatus = () => {
  const navigate = useNavigate();
  const sessionKey = defaultStorageHelper.get("sessionKey");
  const email = defaultStorageHelper.get("email");
  const awardedTime = defaultStorageHelper.get("awardedTime");
  const preferredLanguage = defaultStorageHelper.get("preferredLanguage");
  const won = defaultStorageHelper.get("won");
  const [submissionError, setSubmissionError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [adminSchoolZone, setAdminSchoolZone] = useState("");

  useEffect(() => {
    if (!sessionKey) {
      navigate("/admin");
    }
    getCogntioAdmin();
  }, []);

  const getCogntioAdmin = async () => {
    let res = await Auth.currentAuthenticatedUser();
    setAdminEmail(res.username);
    setAdminSchoolZone(res.attributes.zoneinfo);
  };

  const handleSubmit = async () => {
    if (!sessionKey) {
      return;
    }
    setSubmitting(true);
    setSubmissionError(false);
    try {
      const { won }: any = await campaignClient.call("instantWin", {
        sessionKey: sessionKey,
        adminEmail: adminEmail,
        adminSchoolZone: adminSchoolZone,
        schoolName: adminSchoolZone,
      });
      if (won && won === true) {
        navigate(`/admin/awarded`);
        return;
      } else {
        setSubmissionError(true);
      }
    } catch (error) {
      setSubmissionError(true);
    }
    setSubmitting(false);
  };

  return (
    <>
      <AdminHeader />
      <div className="main1">
        {submissionError ? (
          <div className="main1" style={{ minHeight: "100%" }}>
            <section className="section1 section-full1 section-main1 section--center1">
              <div className="shell1">
                {/* <div className="section__inner1 section__inner--full1"> */}
                <div className="section__content1">
                  <Modal
                    isOpen
                    contentLabel="My dialog"
                    ariaHideApp={false}
                    className="popup"
                    portalClassName="modal"
                    onAfterOpen={() =>
                      (document.body.style.overflow = "hidden")
                    }
                    onAfterClose={() =>
                      (document.body.style.overflow = "unset")
                    }
                    style={{
                      overlay: {
                        zIndex: 1043,
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0,0,0,0.5)",
                      },
                    }}
                  >
                    <div
                      className="mfp-wrap1 mfp-close-btn-in1 mfp-auto-cursor1 mfp-fade mfp-ready1"
                      tabIndex={-1}
                      style={{ overflow: "hidden auto" }}
                    >
                      <div className="mfp-container1 mfp-ajax-holder1 mfp-s-ready1">
                        <div className="mfp-content1">
                          <div className="popup popup--alt popup--small">
                            <div className="popup__inner">
                              <div className="popup__body">
                                <h4>
                                  Something went wrong, please try again later.
                                </h4>
                              </div>
                              <div className="popup__foot">
                                <a
                                  className="btn1 btn--default1 btn--primary1 js-popup-close1 popup__btn1"
                                  onClick={() => navigate("/admin/no-rewards")}
                                >
                                  OK
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <></>
        )}

        {submitting ? (
          <AdminLoading />
        ) : (
          <div className="section-form1 section-form--alt1">
            <div className="shell1">
              <div className="section__inner1">
                <div className="subscribe1 subscribe--alt1">
                  <header className="subscribe__head1">
                    <h3>Participant details</h3>
                    {won == "true" ? (
                      <p>This participant has already received a reward.</p>
                    ) : (
                      <p>
                        Please review the info below before giving the user
                        their reward.
                      </p>
                    )}
                  </header>
                  <div className="subscribe__body1">
                    <ul>
                      <li>
                        <strong>Email:</strong>
                        <a href={`mailto:${email}`}>{email}</a>
                      </li>
                      <li>
                        <strong>Prize:</strong>
                        $15 Gift Card
                      </li>
                      {awardedTime !== "" ? (
                        <li>
                          <strong>Date awarded:</strong>
                          {awardedTime}
                        </li>
                      ) : (
                        <li></li>
                      )}
                      {preferredLanguage !== "" ? (
                        <li>
                          <strong>Participant preferred language:</strong>
                          {preferredLanguage}
                        </li>
                      ) : (
                        <li></li>
                      )}
                    </ul>
                    {won == "false" ? (
                      <div
                        className="subscribe__actions1"
                        style={{ marginTop: "5rem" }}
                      >
                        <a
                          className="btn1 subscribe__btn1 subscribe__btn--small1"
                          onClick={() => handleSubmit()}
                        >
                          Give reward
                        </a>
                        <a
                          className="btn1 btn--border1 subscribe__btn1 subscribe__btn--small1"
                          onClick={() => navigate("/admin/enter-email")}
                        >
                          Cancel
                        </a>
                      </div>
                    ) : (
                      <div
                        className="subscribe__actions1"
                        style={{ marginTop: "5rem" }}
                      >
                        <a
                          className="btn1 subscribe__btn1 subscribe__btn--small1"
                          onClick={() => navigate("/admin/enter-email")}
                        >
                          Back
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <AdminFooter />
    </>
  );
};

export default ParticipantStatus;
