import React from "react";
import Modal from "react-modal";
import spinner from "./fancybox_loading.gif";
import "./index.css";

const Loading: React.FC = () => {
  return (
    <div className="main" style={{ minHeight: "100%" }}>
      <section className="section section-full section-main section--center">
        <div className="shell">
          <div className="section__inner section__inner--full">
            <div className="section__content">
              <Modal
                isOpen
                contentLabel="My dialog"
                ariaHideApp={false}
                className="loadingModalPopup"
                portalClassName="modal"
                style={{
                  overlay: {
                    position: "fixed",
                    zIndex: 1043,
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    //   -webkit-backface-visibility: hidden;
                  },
                }}
              >
                <div className="loading loading--full-height">
                  {/* <img src={spinner} alt="loading..." /> */}
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Loading;
