import { Navigate, Route, Routes } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useStores, StoreContext } from "./stores";
import { Auth } from "aws-amplify";
import { publicCampaignClient, setupApiClient } from "./api";
import Login from "./Pages/Admin/Login";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getSessionClient } from "./api";
import Admin from "./Pages/Admin";
import EnterEmail from "./Pages/Admin/EnterEmail";
import ParticipantStatus from "./Pages/Admin/ParticipantStatus";
import NoRewards from "./Pages/Admin/NoRewards";
import Awarded from "./Pages/Admin/Awarded";
import Open from "./Pages/Open";
import Landing from "./Pages/Open/Landing";
import TriviaQuestion from "./Pages/Open/TriviaQuestion";
import Results from "./Pages/Open/Results";
import SurveyQuestion from "./Pages/Open/SurveyQuestion";
import SurveyComplete from "./Pages/Open/SurveyComplete";
import Closed from "./Pages/Closed";
import ComingSoon from "./Pages/ComingSoon";
import RedemptionOnly from "./Pages/RedemptionOnly";
import { useTranslation } from "react-i18next";
import "./i18n";
import disableBrowserHistory from "./Components/DisableBrowserBack";

type LocationReport = { path: string; hash?: string; search?: string };
let lastLocationReport: LocationReport = { path: "" };

function App() {
  disableBrowserHistory();
  const { i18n } = useTranslation();
  const location = useLocation();

  setInterval(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const { refreshToken } = cognitoUser.getSignInUserSession();
      cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
        setupApiClient(session.idToken.jwtToken);
        // console.log(session.idToken.jwtToken);
      });
    } catch (e) {
      throw e;
    }
  }, 1200000);

  const stores = useStores();

  const { route, user } = useAuthenticator((context) => [context.route]);

  if (route === "authenticated" && user) {
    stores.authStore.setup();
  }

  async function reportLocation(currentLocation: LocationReport) {
    await getSessionClient()
      .createFlowBuilder()
      .setTag("navigation")
      .addMetadata(currentLocation)
      .commit();
  }

  if (i18n.language === "fr") {
    document.title = `Objectif carrière RBC`;
  } else {
    document.title = `RBC Career Quest Quiz`;
  }

  useEffect(() => {
    const currentLocation: LocationReport = { path: location.pathname };
    if (location.hash && location.hash !== "#") {
      currentLocation.hash = location.hash;
    }
    if (location.search && location.search !== "?") {
      currentLocation.search = location.search;
    }
    if (
      lastLocationReport.path !== currentLocation.path ||
      lastLocationReport.hash !== currentLocation.hash ||
      lastLocationReport.search !== currentLocation.search
    ) {
      reportLocation(currentLocation);
      lastLocationReport = currentLocation;
    }
  });

  const [inventoryCount, setInventoryCount] = useState<number>();

  useEffect(() => {
    const getInventory = async () => {
      const res: any = await publicCampaignClient.call(
        "getGiftCardInventories",
        {}
      );
      setInventoryCount(res.giftCardInventories[0].unassignedCount);
    };
    getInventory();
  }, []);

  return route === "authenticated" ? (
    <StoreContext.Provider value={stores}>
      <Routes>
        <Route path="/admin" element={<Admin />}>
          {inventoryCount! < 1 ? (
            <Route index element={<Navigate to="no-rewards" />} />
          ) : (
            <Route index element={<Navigate to="enter-email" />} />
          )}
          <Route path="enter-email" element={<EnterEmail />} />
          <Route path="participant-status" element={<ParticipantStatus />} />
          <Route path="no-rewards" element={<NoRewards />} />
          <Route path="awarded" element={<Awarded />} />
        </Route>

        <Route path="/admin" element={<Login />} />

        <Route path="/" element={<Open />}>
          <Route
            path="/"
            element={<Landing inventoryCount={inventoryCount} />}
          />
          <Route path="trivia-question" element={<TriviaQuestion />} />
          <Route
            path="results"
            element={<Results inventoryCount={inventoryCount} />}
          />
          <Route path="survey-question" element={<SurveyQuestion />} />
          <Route
            path="survey-complete"
            element={<SurveyComplete inventoryCount={inventoryCount} />}
          />
          <Route path="closed" element={<Closed />} />
          <Route path="coming" element={<ComingSoon />} />
        </Route>

        <Route path="redemption" element={<RedemptionOnly />}>
          <Route path="/redemption" element={<ComingSoon />} />
        </Route>
      </Routes>
    </StoreContext.Provider>
  ) : (
    <Routes>
      <Route path="/admin" element={<Login />} />

      <Route path="/" element={<Open />}>
        <Route path="/" element={<Landing inventoryCount={inventoryCount} />} />
        <Route path="trivia-question" element={<TriviaQuestion />} />
        <Route
          path="results"
          element={<Results inventoryCount={inventoryCount} />}
        />
        <Route path="survey-question" element={<SurveyQuestion />} />
        <Route
          path="survey-complete"
          element={<SurveyComplete inventoryCount={inventoryCount} />}
        />
        <Route path="closed" element={<Closed />} />
        <Route path="coming" element={<ComingSoon />} />
      </Route>

      <Route path="redemption" element={<RedemptionOnly />}>
        <Route path="/redemption" element={<ComingSoon />} />
      </Route>
    </Routes>
  );
}

export default App;
