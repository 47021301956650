import { defaultStorageHelper } from "@spry/campaign-client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (nav && !e.target.closest("header__nav.is-active")) {
        setNav(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [nav]);

  const handleLanguageChange = (e: any, lang: string) => {
    e.preventDefault();
    defaultStorageHelper.set("lang", lang);
    i18n.changeLanguage(lang);
  };

  return (
    <header className="header" style={{ padding: "none" }}>
      <div className="shell">
        <div className="header__inner">
          <div className="header__inner-content">
            <a href="/" className="logo header__logo">
              <img
                src="assets/images/svg/logo.svg"
                alt=""
                width={204}
                height={20}
              />
            </a>
            <a
              className="nav-trigger js-nav-trigger visible-sm-inline-block visible-xs-inline-block"
              onClick={() => setNav(true)}
            >
              <span></span>
              <span></span>
              <span></span>
            </a>
          </div>
          <nav className={`nav header__nav${nav ? ".is-active" : ""}`}>
            <ul>
              <li>
                <a href={t("links.privacy")} target="_blank">
                  {t("Footer.2")}
                </a>
              </li>
              <li>
                <a
                  onClick={(e) =>
                    handleLanguageChange(e, i18n.language == "en" ? "fr" : "en")
                  }
                >
                  {i18n.language == "en" ? "Français" : "English"}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
