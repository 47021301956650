import { defaultStorageHelper } from "@spry/campaign-client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { publicCampaignClient } from "../../../api";
import Loading from "../../../Components/Loading";
import MyModal from "../../../Components/MyModal";

const Results = (inventoryCount: any) => {
  const { t, i18n } = useTranslation();
  const sessionKey = defaultStorageHelper.get("sessionKey");
  const schoolName = defaultStorageHelper.get("schoolName");
  const schoolLocation = defaultStorageHelper.get("schoolLocation");
  const schoolLocationLink = defaultStorageHelper.get("schoolLocationLink");
  const result = defaultStorageHelper.get("result")
    ? defaultStorageHelper.get("result")
    : "0";
  const navigate = useNavigate();
  const [submissionError, setSubmissionError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!sessionKey || !schoolName || !schoolLocation) {
      navigate("/");
    }
    postResult();
  }, []);

  const postResult = () => {
    setLoading(true);
    try {
      const res: any = publicCampaignClient.call("result", {
        sessionKey: sessionKey,
        schoolName: schoolName,
        result: result,
      });
    } catch (error) {
      console.log(error);
      setSubmissionError(true);
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}

      {submissionError ? (
        <MyModal
          copy={
            i18n.language === "en"
              ? "Something went wrong, please try again later."
              : "Quelque chose c'est mal passé. Merci d'essayer plus tard."
          }
        />
      ) : (
        <></>
      )}

      <section className="section-main section-main--alt section-alt section--full">
        <div className="shell">
          <div className="section__inner">
            <div className="section__content">
              <div className="section__content-inner">
                <h3>
                  {inventoryCount && inventoryCount.inventoryCount > 0
                    ? t("quiz-complete-prize.1")
                    : t("quiz-complete-no-prize.1")}
                </h3>
                <p>
                  {inventoryCount && inventoryCount.inventoryCount > 0
                    ? t("quiz-complete-prize.2")
                    : t("quiz-complete-no-prize.2")}{" "}
                </p>
                {inventoryCount && inventoryCount.inventoryCount > 0 && (
                  <>
                    <h6>{t("quiz-complete-prize.2a")}</h6>
                    <div className="section__content-image">
                      <img
                        src="assets/images/svg/dd-gift-card.svg"
                        width={248}
                        height={164}
                      />
                      <div className="section__figure section__content-figure">
                        <img
                          src="assets/images/svg/figures-3.svg"
                          width={183}
                          height={211}
                          className="hidden-xs hidden-sm"
                        />
                        <img
                          src="assets/images/svg/figures-3-mobile.svg"
                          width={441}
                          height={192}
                          className="visible-xs-block visible-sm-block"
                        />
                      </div>
                    </div>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            schoolName === "Other" || schoolName === "zOther"
                              ? t("other.1")
                              : t("quiz-complete-prize.3"),
                        }}
                      />
                      {schoolLocationLink !== "" ? (
                        <a href={`${schoolLocationLink}`} target="__blank">
                          {`${
                            schoolName === "Other" || schoolName === "zOther"
                              ? ""
                              : schoolName
                          }`}
                          , {schoolLocation}
                        </a>
                      ) : (
                        ""
                      )}
                      {schoolName === "Other" || schoolName === "zOther"
                        ? ""
                        : t("quiz-complete-prize.3a")}
                    </p>
                    <p>{t("quiz-complete-prize.4")}</p>
                    <small>{t("quiz-complete-prize.5")}</small>{" "}
                  </>
                )}
                {schoolName === "Other" || schoolName === "zOther" ? (
                  ""
                ) : (
                  <>
                    <h5>{t("quiz-complete-prize.6")}</h5>
                    <p>{t("quiz-complete-prize.7")}</p>
                  </>
                )}
              </div>
              {schoolName === "Other" || schoolName === "zOther" ? (
                ""
              ) : (
                <a
                  onClick={() => navigate("/survey-question")}
                  className="btn  btn--warning section__btn"
                >
                  {t("quiz-complete-prize.8")}{" "}
                </a>
              )}
            </div>
            <div className="section__image section__image--alt">
              <img
                src={
                  inventoryCount && inventoryCount.inventoryCount > 0
                    ? i18n.language === "en"
                      ? "assets/images/svg/map6-prize.svg"
                      : "assets/images/svg/map6-prize-fr.svg"
                    : i18n.language === "en"
                    ? "assets/images/svg/map6.svg"
                    : "assets/images/svg/map6-fr.svg"
                }
                alt=""
                width={1085}
                height={648}
                className="hidden-xs hidden-sm"
              />
              <img
                src={
                  inventoryCount && inventoryCount.inventoryCount > 0
                    ? i18n.language === "en"
                      ? "assets/images/svg/map6-prize-mobile.svg"
                      : "assets/images/svg/map6-prize-mobile-fr.svg"
                    : i18n.language === "en"
                    ? "assets/images/svg/map6-mobile.svg"
                    : "assets/images/svg/map6-prize-mobile-fr.svg"
                }
                alt=""
                width={519}
                height={398}
                className="visible-xs-block visible-sm-block"
              />
            </div>
            <div className="section__background hidden-xs hidden-sm">
              <img
                src="assets/images/background-1.png"
                alt=""
                width={2088}
                height={3083}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Results;
