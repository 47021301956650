import React from "react";

const AdminFooter = () => {
  return (
    <footer className="footer1 footer--alt1">
      <div className="shell1">
        <div className="footer__inner1">
          <p>Royal Bank of Canada © 1995-2023</p>
        </div>
      </div>
    </footer>
  );
};

export default AdminFooter;
