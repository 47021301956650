import { SPRYCampaignClient, SessionClient } from "@spry/campaign-client";

const endpoint =
  process.env.REACT_APP_API_ENDPOINT || "http://localhost:31337/admin";
const campaignClient = new SPRYCampaignClient(endpoint);

const publicEndpoint =
  process.env.REACT_APP_API_PUBLIC_ENDPOINT || "http://localhost:31337/api";
const publicCampaignClient = new SPRYCampaignClient(publicEndpoint!);

let sessionClient: SessionClient = publicCampaignClient.recoverSession();

function getSessionClient() {
  return sessionClient;
}

function setSessionClient(_sessionClient: SessionClient) {
  return (sessionClient = _sessionClient);
}

const setupApiClient = (token: string) => {
  campaignClient.setAuthorization(token);
};

export {
  endpoint,
  campaignClient,
  getSessionClient,
  setSessionClient,
  publicCampaignClient,
  setupApiClient,
};
