import { useNavigate } from "react-router-dom";
import AdminFooter from "../../../Components/AdminFooter";
import AdminLoginHeader from "../../../Components/AdminLoginHeader";
import { useStores } from "../../../stores";

const NoRewards = () => {
  const { authStore } = useStores();
  const navigate = useNavigate();

  const logOut = async () => {
    try {
      await authStore.logout();
      navigate("/admin", { replace: true });
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };
  return (
    <>
      <AdminLoginHeader />
      <div className="main1">
        <div className="section-form1">
          <div className="shell1">
            <div className="section__inner1">
              <div className="section__content1">
                <h3>No rewards</h3>
                <p>
                  Unfortunately, there are no rewards remaining for the
                  participant to receive.
                </p>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    logOut();
                  }}
                  className="btn1 section__btn1"
                >
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminFooter />
    </>
  );
};

export default NoRewards;
