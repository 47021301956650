import { defaultStorageHelper } from "@spry/campaign-client";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";

const LangModal = () => {
  const [language, setLanguage] = useState("");
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lang: string) => {
    defaultStorageHelper.set("lang", lang);
    i18n.changeLanguage(lang);
  };

  return language === "" ? (
    <div className="main" style={{ minHeight: "100%" }}>
      <section className="section section-full section-main section--center">
        <div className="shell">
          <div className="section__inner section__inner--full">
            <div className="section__content">
              <Modal
                isOpen
                contentLabel="My dialog"
                ariaHideApp={false}
                className="popup"
                portalClassName="modal"
                onAfterOpen={() => (document.body.style.overflow = "hidden")}
                onAfterClose={() => (document.body.style.overflow = "unset")}
                style={{
                  overlay: {
                    position: "fixed",
                    zIndex: 1043,
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0,0,0,0.5)",
                  },
                }}
              >
                <div
                  className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
                  style={{ overflow: "hidden auto", position: "relative" }}
                >
                  <div className="mfp-content">
                    <div className="popup">
                      <h4>{t("Language.1")}</h4>
                      <div className="popup__actions">
                        <button
                          className="btn btn--warning"
                          onClick={() => {
                            setLanguage("fr");
                            handleLanguageChange("fr");
                          }}
                          // disabled={i18n.language === "fr" ? true : false}
                          style={{ marginRight: "10px" }}
                        >
                          {t("Language.3")}
                        </button>
                        <button
                          className="btn btn--warning"
                          onClick={() => {
                            setLanguage("en");
                            handleLanguageChange("en");
                          }}
                          // disabled={i18n.language === "en" ? true : false}
                        >
                          {t("Language.2")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </section>
    </div>
  ) : (
    <></>
  );
};

export default LangModal;
